import React, { Component } from 'react'
import JsonData from '../data/data.json';
export default class Privacy extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div id="privacy1">
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
              <a className="navbar-brand page-scroll" href="#/">
                <img src="https://cdn.ellll.com/gw/logo-img.png" alt="" className="carousel_img"/>
              </a>{" "}
            </div>

            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >

            </div>
          </div>
        </nav>
        <div className="title" style={{fontSize: '32px',marginTop: '100px'}}>隐私政策声明</div>
        <div className="content" style={{fontSize: '24px',marginBottom:'30px',}}>
          <div>我们注重对您个人隐私的保护。有时候我们需要某些信息才能为您提供您请求的服务，本隐私声明解释了这些情况下的数据收集和使用情况。本隐私声明适用于本网站的所有相关服务。如果您访问本网站、使用本网站的任何服务，那么您便接受了本隐私声明。</div>
          <div>用户信息的获取</div>
          <div>我们获取用户信息的主要目的在于向您提供一个顺畅、高效的购物流程，并致力于不断完善和提升您的购物体验。</div>
          <div>我们可能获取的用户信息类型包括：</div>
          <div>注册信息（如，用户名、及在注册过程中提供的其他信息）；</div>
          <div>个人或公司联系方式（如，姓名、公司名称、通讯地址、电邮地址、电话、手机号码、传真号码）；</div>
          <div>订购信息（如，(i) 订购人和收货人名称、送货地址、电邮地址和电话号码等与订购相关的任何信息，(ii) 在交付文件上的签名，(iii) 1号店网站帐户及帐户信息，(iv)
            为帮助我们提供服务（包括特定服务）而提供给我们的信息）；
          </div>
          <div>用于您身份识别的信息（如，身份证号码、护照号码、驾驶证号码）；</div>
          <div>指示我们将商品或服务提供给其他人士时提供的该人士的有关信息（如，该人士的姓名、送货地址、电邮地址和电话号码）；</div>
          <div>支付信息（如，订单支付详情）和财务信息（如，银行账号）；</div>
          <div>其他个人、公司、单位、或社会实体信息。</div>
          <div>我们可能会就下列各种情况，获得您的用户信息：</div>
          <div>您在访问或使用本网站服务时提供的信息；</div>
          <div>您在与我们的沟通中自动获取的信息：无论您何时与我们沟通，我们接收并储存的信息；</div>
          <div>从其他来源获取的信息：如您参加我们或我们与合作伙伴组织的任何活动。</div>
          <div>顾客信息的使用</div>
          <div>我们可能会利用我们获取的用户信息：</div>
          <div>向您提供所需要的产品或服务（如，定向采购、货物配送）；</div>
          <div>处理和收取款项；</div>
          <div>就您的要求向您提供咨询服务，或向您做出回应或与您沟通；</div>
          <div>向您提供我们认为您可能会感兴趣的产品或服务；</div>
          <div>为提升我们的服务，联系您进行调研；</div>
          <div>进行数据分析（如，购买行为或趋势分析、市场或顾客调查、财务分析）；</div>
          <div>管理或认可您参加各种活动的资格，如线上或线下活动、优惠或促销活动；</div>
          <div>经营、评估和完善我们的业务（包括开发新产品和服务；管理我们的通信；判断我们的销售、营销和广告效果；分析和强化我们的产品、服务和网站；和开展会计、审计、记账、对账和收集活动）；</div>
          <div>在事先获 得您同意的情况下，向您指定的联系人发送信息；</div>
          <div>防止各种违法或犯罪活动和其他法律责任；</div>
          <div>遵守法律规定和我们的政策规则。</div>
          <div>用户信息的共享、披露与转让</div>
          <div>用户的信息是我们业务的重要组成部分，我们不会出售或以其他方式共享您的个人信息，但本《隐私声明》中已列明的如下情况除外。</div>
          <div>合作伙伴：</div>
          <div>我们可能会与我们的关联公司、入驻商家或合作伙伴（包括但不限于，您通过我们提供的联合登陆方式登陆的其它网站，以下统称“合作伙伴”）共享用户信息。您可以辨别您的使用或交易行为何时会涉及我们的合作伙伴，我们会与该合作伙伴分享与这些使用或交易行为有关的用户信息。</div>
          <div>第三方服务提供者：</div>
          <div>我们有可能聘请外部公司或个人代表我们提供某些产品或服务，包括但不限于，提供运营、维护、IT平台和/或技术服务、数据安全或备份服务、处理订单、递送货物、发送信函或电子邮件、清除用户名单中的重复信息、分析数据、提供市场营销帮助、提供搜索结果和链接（包括付费搜索名单和链接）、处理收/付款事项以及提供用户服务。在该等情况下，我们可能会共享用户信息。</div>
          <div>征得您的同意：</div>
          <div>除以上规定及本《隐私声明》已列明的情况外，当您的信息有可能披露给第三方时，您将会得到通知，并且您将有机会选择不与第三方共享此信息。</div>
          <div>在下列情况下，我们可能还会披露您的相关信息：</div>
          <div>根据法律、法规或法律程序（如，法院法令或传召出庭令）的规定；</div>
          <div>根据政府部门（如，行政机构、执法机构）的要求；</div>
          <div>为了保护本网站、我们的用户或其他人的权利及其财产或安全，或当我们认为对防止实际损害或财务损失，或调查可疑或实际非法活动来说，披露乃属必要或适当时。这也包括为防止欺诈等违法活动和减少信用风险而与其他公司和组织交换信息。</div>
          <div>我们希望您能够理解，我们可能会向第三方出售或转让本网站，和/或允许承继方承继本网站的服务。在该等情况中，作为本网站不可分割一部分，您的个人信息也将被提供给该等第三方和/或承继方，以使其可以继续为您提供网站服务。接受本隐私声明意味着您已同意该等信息提供。</div>
          <div>未成年人信息的保护</div>
          <div>我们非常重视对未成年人（依照现行法律的定义）个人信息的保护。我们不希望未成年人直接使用本网站提供的产品或服务，我们希望未成年人与其监护人一起，或在监护人的监督下使用本网站的产品或服务。</div>
          <div>我们既不主动寻求、也不希望收到直接来自未成年人的个人信息；但我们无法保证时刻都能准确地了解到访问或使用本网站的访问者年龄。如果未成年人在无监护人同意的情况下向我们提供了他/她的个人信息，我们建议他/她的监护人与我们联系以删除该信息，并且通知我们以后不再向该未成年人发送任何信息。</div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              Copyright © 2019 habit Commune Inc.All Rights Reserved.
              {/*<a href="http://www.templatewire.com" rel="nofollow">*/}
              {/*TemplateWire*/}
              {/*</a>*/}
            </p>
            <p>沪ICP备19003472号-1 上海形者网络科技</p>
          </div>
        </div>
      </div>
    )
  }
}