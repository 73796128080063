import React, { Component } from "react";

export class testimonials extends Component {
  render() {
    return (
      <div id="testimonials">
        <div >
          {/*<div className="section-title text-center">*/}
            {/*<h2>使用指南</h2>*/}
          {/*</div>*/}
          <div className="row">
            <img src="https://cdn.ellll.com/gw/employ-img.jpg" alt="" className="employ-img"/>
            {/*{this.props.data*/}
              {/*? this.props.data.map((d, i) => (*/}
                  {/*<div key={`${d.name}-${i}`} className="col-md-4">*/}
                    {/*<div className="testimonial">*/}
                      {/*<div className="testimonial-image">*/}
                        {/*{" "}*/}
                        {/*<img src={d.img} alt="" />{" "}*/}
                      {/*</div>*/}
                      {/*<div className="testimonial-content">*/}
                        {/*<p>"{d.text}"</p>*/}
                        {/*<div className="testimonial-meta"> - {d.name} </div>*/}
                      {/*</div>*/}
                    {/*</div>*/}
                  {/*</div>*/}
                {/*))*/}
              {/*: "loading"}*/}
          </div>
        </div>
      </div>
    );
  }
}

export default testimonials;
