import React, { Component } from "react";

export class Gallery extends Component {
  render() {
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>习惯公社展示</h2>
            <p>
             21天每日早起打卡计划
            </p>
          </div>
          <div className="row">
            <div className="portfolio-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="https://cdn.ellll.com/gw/01-large.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>秀一秀</h4>
                      </div>
                      <img
                        src="https://cdn.ellll.com/gw/01-small.JPG"
                        className="img-responsive img-portfolio"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="https://cdn.ellll.com/gw/02-large.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>家长视角</h4>
                      </div>
                      <img
                        src="https://cdn.ellll.com/gw/02-small.JPG"
                        className="img-responsive img-portfolio"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="https://cdn.ellll.com/gw/03-large.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>孩子打卡习惯</h4>
                      </div>
                      <img
                        src="https://cdn.ellll.com/gw/03-small.JPG"
                        className="img-responsive img-portfolio"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="https://cdn.ellll.com/gw/04-large.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>管理员组织架构展示与操作</h4>
                      </div>
                      <img
                        src="https://cdn.ellll.com/gw/04-small.JPG"
                        className="img-responsive img-portfolio"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="https://cdn.ellll.com/gw/05-large.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>单习惯列表展示</h4>
                      </div>
                      <img
                        src="https://cdn.ellll.com/gw/05-small.JPG"
                        className="img-responsive img-portfolio"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="https://cdn.ellll.com/gw/06-large.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>班主任班级习惯展示</h4>
                      </div>
                      <img
                        src="https://cdn.ellll.com/gw/06-small.JPG"
                        className="img-responsive img-portfolio"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="https://cdn.ellll.com/gw/07-large.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>校长设置学校信息以及个人信息</h4>
                      </div>
                      <img
                        src="https://cdn.ellll.com/gw/07-small.JPG"
                        className="img-responsive img-portfolio"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="https://cdn.ellll.com/gw/08-large.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>校长主页信息</h4>
                      </div>
                      <img
                        src="https://cdn.ellll.com/gw/08-small.JPG"
                        className="img-responsive img-portfolio"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="https://cdn.ellll.com/gw/09-large.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>班主任邀请以及查看学生列表</h4>
                      </div>
                      <img
                        src="https://cdn.ellll.com/gw/09-small.JPG"
                        className="img-responsive img-portfolio"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
