import React, { Component } from "react";

export class features extends Component {
  render() {
    return (
      <div className="text-center" style={{paddingTop: '100px'}} id="features">
        <div>
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>下载</h2>
          </div>
          <div className="row">
            <img src="https://cdn.ellll.com/down-load.png" alt="" className="downloadImg"/>
            {/*{this.props.data*/}
              {/*? this.props.data.map((d,i) => (*/}
                  {/*<div  key={`${d.title}-${i}`} className="col-xs-6 col-md-3">*/}
                    {/*{" "}*/}
                    {/*<i className={d.icon}></i>*/}
                    {/*<h3>{d.title}</h3>*/}
                    {/*<p>{d.text}</p>*/}
                  {/*</div>*/}
                {/*))*/}
              {/*: "Loading..."}*/}
          </div>
        </div>
      </div>
    );
  }
}

export default features;
