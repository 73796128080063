import React, { Component } from "react";

export class Navigation extends Component {
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if(anchorElement) { anchorElement.scrollIntoView(); }
    }
  }
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top">
              <img src="https://cdn.ellll.com/gw/logo-img.png" alt="" className="carousel_img"/>
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a className="page-scroll" onClick={()=>this.scrollToAnchor('features')}>
                  下载
                </a>
              </li>
              <li>
                <a className="page-scroll" onClick={()=>this.scrollToAnchor('about')}>
                  缘起
                </a>
              </li>
              <li>
                <a className="page-scroll" onClick={()=>this.scrollToAnchor('services')}>
                  功能
                </a>
              </li>
              <li>
                <a className="page-scroll" onClick={()=>this.scrollToAnchor('portfolio')}>
                  展示
                </a>
              </li>
              <li>
                <a className="page-scroll" onClick={()=>this.scrollToAnchor('testimonials')}>
                  使用指南
                </a>
              </li>
              <li>
                <a className="page-scroll" onClick={()=>this.scrollToAnchor('team')}>
                  团队
                </a>
              </li>
              <li>
                <a className="page-scroll" onClick={()=>this.scrollToAnchor('contact')}>
                  加入我们
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
