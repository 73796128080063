import React, { Component } from "react";

export class Header extends Component {
  render() {
    return (
      <header id="header">
        {/*<div className="intro">*/}
          {/*<div className="overlay">*/}
            {/*<div className="container">*/}
              {/*<div className="row">*/}
                {/*<div className="col-md-8 col-md-offset-2 intro-text">*/}
                  {/*<h1>*/}
                    {/*{this.props.data ? this.props.data.title : "Loading"}*/}
                    {/*<span></span>*/}
                  {/*</h1>*/}
                  {/*<p>*/}
                    {/*{this.props.data ? this.props.data.paragraph : "Loading"}*/}
                  {/*</p>*/}
                  {/*<a*/}
                    {/*href="#features"*/}
                    {/*className="btn btn-custom btn-lg page-scroll"*/}
                  {/*>*/}
                    {/*查看更多*/}
                  {/*</a>{" "}*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</div>*/}
        <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
          {/*<ol className="carousel-indicators">*/}
            {/*<li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>*/}
            {/*<li data-target="#carousel-example-generic" data-slide-to="1"></li>*/}
            {/*<li data-target="#carousel-example-generic" data-slide-to="2"></li>*/}
          {/*</ol>*/}
          <div className="carousel-inner" role="listbox">
            <div className="item active carousel1" style={{backgroundImage: "url(https://cdn.ellll.com/gw/carousel-img.png)"}}>
              {/*<img src="img/carousel-img.png" alt="..." style={{width: '100%'}}/>*/}
                <div className="carousel-caption">
                </div>
            </div>
            <div className="item carousel1" style={{backgroundImage: "url(https://cdn.ellll.com/gw/carousel-img2.jpg)"}}>
              {/*<img src="img/carousel-img2.jpg" alt="..." style={{width: '100%'}}/>*/}
                <div className="carousel-caption">
                </div>
            </div>
            <div className="item carousel1" style={{backgroundImage: "url(https://cdn.ellll.com/gw/carousel-img3.jpg)"}}>
              {/*<img src="img/carousel-img3.jpg" alt="..." style={{width: '100%'}}/>*/}
              <div className="carousel-caption">
              </div>
            </div>
          </div>
          <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
            <img src="https://cdn.ellll.com/gw/left-icon.png" alt="..." className="left-icon"/>
          </a>
          <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
            <img src="https://cdn.ellll.com/gw/right-icon.png" alt="..." className="right-icon"/>
          </a>
        </div>
      </header>
    );
  }
}

export default Header;
